.blog:not(.background-secondary) {
  margin: 5rem 0;
}

.blog.background-secondary {
  padding: 4rem 0 3rem;
}

@media screen and (min-width: 750px) {
  .blog:not(.background-secondary) {
    margin: calc(5rem + var(--page-width-margin)) 0;
  }

  .blog.background-secondary {
    padding-top: calc(5rem + var(--page-width-margin));
    padding-bottom: calc(5rem + var(--page-width-margin));
  }
}

.blog-placeholder {
  margin: 0 1.5rem;
  background: rgb(var(--color-background));
}

@media screen and (min-width: 750px) {
  .blog-placeholder {
    text-align: center;
    width: 50%;
    margin: 0;
  }
}

.blog-placeholder__content {
  padding: 3rem;
  background: rgba(var(--color-foreground), 0.04);
}

.blog-placeholder .placeholder {
  position: relative;
}

.blog-placeholder .placeholder-svg {
  height: auto;
  max-width: 80rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.blog-placeholder h2 {
  margin: 0;
}

.blog-placeholder .rte-width {
  margin-top: 1.2rem;
  color: rgba(var(--color-foreground), 0.75);
}

@media screen and (max-width: 749px) {
  .blog:not(.no-heading) {
    margin-top: -1rem;
  }
}

@media screen and (min-width: 750px) {
  .blog.no-heading {
    margin-top: calc(6rem + var(--page-width-margin));
  }
}

.background-secondary .title-wrapper-with-link {
  margin-top: 0;
}

.blog__title {
  margin: 0;
}

.blog__posts.articles-wrapper {
  margin-bottom: 1rem;
}

@media screen and (min-width: 990px) {
  .blog__posts.articles-wrapper {
    margin-bottom: 0;
  }
}

.blog__posts.articles-wrapper .article {
  scroll-snap-align: start;
}

@media screen and (min-width: 750px) {
  .blog__posts .article + .article {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 749px) {
  .blog__post.article {
    width: calc(100% - 3rem);
    padding-left: 0.5rem;
  }
}

.background-secondary .article-card,
.background-secondary .blog-placeholder__content {
  background-color: rgb(var(--color-background));
}

.blog__button {
  margin-top: 3rem;
}

@media screen and (min-width: 750px) {
  .blog__button {
    margin-top: 5rem;
  }
}
